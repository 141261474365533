const ReportingIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11' fill='none'>
      <path
        d='M1 3.46352L4.21429 1.53495L6.78571 3.46352L10 0.89209'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M9.35707 9.89188V4.74902' stroke='#E052C2' strokeLinecap='round' />
      <path d='M4.21449 9.89188V4.74902' stroke='#E052C2' strokeLinecap='round' />
      <path d='M6.78578 9.89202V6.67773' stroke='#E052C2' strokeLinecap='round' />
      <path d='M1.64272 9.89202V6.67773' stroke='#E052C2' strokeLinecap='round' />
    </svg>
  );
};

export default ReportingIcon;
