import { Box, Button, Toolbar, useMediaQuery, useTheme, Drawer, IconButton, Popover, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Image from 'next/image';
import Link from 'next/link';
import { useState } from 'react';
import logoBlack from '../../../public/images/Logo-Black.svg';
import logo from '../../../public/images/Logo-White.svg';
import BookDemoButton from '../../@core/components/BookDemoButton';
import MenuDrawer from './MenuDrawer';
import AutonomusIcon from './Icons/AutonomusIcon';
// import ReceiveableIcon from './Icons/ReceiveableIcon';
import AutomationIcon from './Icons/AutomationIcon';
import RiskManagementIcon from './Icons/RiskManagementIcon';
import ReportingIcon from './Icons/ReportingIcon';
import EngagementIcon from './Icons/EngagementIcon';
import FinancialInstitutions from './Icons/FinancialInstitutions';
import HealthCareIcon from './Icons/HealthCare';
import SMBIcon from './Icons/SMB';
import LawFirmIcon from './Icons/LawFirmIcon';
import ReceiveableIcon from './Icons/RecieveableIcon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const SolutionsSubMenu = [
  { title: 'Autonomous Collections', link: 'autonomus-collections', icon: AutonomusIcon },
  { title: 'Accounts Receivable Management', link: 'accounts-receivable-management', icon: ReceiveableIcon },
  {
    title: 'AI Automation and Workflow Optimization',
    link: 'ai-automation-and-workflow-optimization',
    icon: AutomationIcon,
  },
  { title: 'Advanced Risk Management', link: 'advanced-risk-management', icon: RiskManagementIcon },
  { title: 'Financial Reporting and Analysis', link: 'financial-reporting-and-analysis', icon: ReportingIcon },
  { title: 'Customer Engagement and Self-Service', link: 'customer-engagement-and-self-service', icon: EngagementIcon },
];

const IndustriesSubMenu = [
  { title: 'Financial Institutions', link: 'financial-institutions', icon: FinancialInstitutions },
  { title: 'Healthcare', link: 'healthcare', icon: HealthCareIcon },
  { title: 'Small and Mid-sized Business Firms', link: 'sbms', icon: SMBIcon },
  { title: 'Law Firms', link: 'law-firms', icon: LawFirmIcon },
];

const SubMenuItem = ({ title, link, icon }: { title: string; link: string; icon: any }) => {
  const IconComponent = icon;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Link href={link} passHref>
        <Button>
          <Box sx={{ display: 'flex', gap: '10px' }}>
            <Box sx={{ pt: '3px', display: 'flex', alignItems: 'start' }}>
              <IconComponent />
            </Box>
            <Typography
              sx={{
                fontSize: '1.2rem',
                color: '#3E3E3E',
                fontWeight: '600',
                textAlign: 'left',
              }}
            >
              {title}
            </Typography>
          </Box>
        </Button>
      </Link>
    </Box>
  );
};

const NavigationBar = ({
  variant = 'primary',
  bookButtonVariant = 'primary',
  buttonText = 'Book a demo',
  showButton = true,
}) => {
  const theme = useTheme();
  const isTab = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [submenuItems, setSubmenuItems] = useState<
    null | { title: string; link: string; icon?: any; onClick?: () => void }[][]
  >(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const isSecondaryVariant = variant === 'secondary';

  const openMainMenu = () => {
    setSubmenuItems(null);
    setDrawerOpen(true);
  };

  const openSolutionsMenu = (e: any) => {
    {
      isMobile && setSubmenuItems([SolutionsSubMenu, IndustriesSubMenu]);
    }
    {
      !isMobile && setAnchorEl(e.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const MainMenu = [
    { title: 'Products', link: '/products' },
    { title: 'Solutions', link: '#', onClick: (e: any) => openSolutionsMenu(e) },
    { title: 'Resources', link: 'https://blog.financeops.ai/', target: '_blank' },
    { title: 'Pricing', link: '/pricing' },
    { title: 'About us', link: '/about-us' },
  ];
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        '& .MuiToolbar-root': {
          minHeight: 'unset',
        },
        '& .MuiToolbar-root .MuiButtonBase-root': {
          color: isSecondaryVariant ? theme.palette.common.black : theme.palette.common.white,
          fontWeight: 600,
          fontSize: isTab ? '0.8rem' : '1.6rem',
        },
        '& .MuiButton-icon': {
          ml: '2px',
        },
        '& .MuiButtonBase-root': {
          textWrap: 'nowrap',
        },
      }}
    >
      <Link href='/' passHref>
        <Box>
          <Image src={isSecondaryVariant ? logoBlack : logo} alt={'FinanceOps'} width={120} height={30} />
        </Box>
      </Link>

      {isMobile ? (
        <>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='menu'
            onClick={openMainMenu}
            sx={{ ml: 'auto', mt: '-8px' }}
          >
            <MenuIcon
              sx={{
                color: isSecondaryVariant ? theme.palette.common.black : theme.palette.common.white,
                // height:'18px',
                fontSize: '24px',
              }}
            />
          </IconButton>
          <Drawer
            anchor='right'
            open={drawerOpen}
            onClose={closeDrawer}
            sx={{ '& .MuiDrawer-paper': { width: '100vw' } }}
          >
            <MenuDrawer
              items={submenuItems || MainMenu}
              backAction={submenuItems ? openMainMenu : undefined}
              closeDrawer={closeDrawer}
              variant={isSecondaryVariant ? 'secondary' : 'primary'}
            />
          </Drawer>
        </>
      ) : (
        <>
          <Toolbar
            sx={{
              ...(isTab && {
                px: '0',
                '& .MuiButtonBase-root ': {
                  fontSize: '1rem',
                },
              }),
            }}
          >
            {MainMenu.map((item, index) => {
              return item?.onClick ? (
                <Button onClick={item.onClick} endIcon={<KeyboardArrowDownIcon />}>
                  {item.title}
                </Button>
              ) : (
                <Link href={item.link} passHref target={item.target || '_self'}>
                  <Button>{item.title}</Button>
                </Link>
              );
            })}
          </Toolbar>
          {!isTab && showButton && (
            <Box sx={{ ml: '160px' }}>
              <BookDemoButton variant={bookButtonVariant as any} text={buttonText} />
            </Box>
          )}
        </>
      )}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, background: '#fff' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '600px',
              maxWidth: '80vw',
              flexWrap: 'wrap',
            }}
          >
            {SolutionsSubMenu?.map((value, index) => {
              return (
                <Box key={index} sx={{ width: '45%' }}>
                  <SubMenuItem title={value.title} link={value.link} icon={value.icon} />
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box sx={{ p: 2, background: '#fff', pt: '0' }}>
          <Typography
            sx={{
              color: '#3E3E3E',
              fontSize: '12px',
              fontWeight: 700,
              pb: '25px',
              pl: '10px',
            }}
          >
            BY INDUSTRY
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '600px',
              maxWidth: '80vw',
              flexWrap: 'wrap',
            }}
          >
            {IndustriesSubMenu?.map((value, index) => {
              return (
                <Box key={index} sx={{ width: '45%' }}>
                  <SubMenuItem title={value.title} link={value.link} icon={value.icon} />
                </Box>
              );
            })}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default NavigationBar;
