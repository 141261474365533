const FinancialInstitutions = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='12' viewBox='0 0 11 12' fill='none'>
      <path
        d='M10.6109 11.4811H6.42264C6.22856 11.4811 6.07129 11.3239 6.07129 11.1298V0.870362C6.07129 0.756089 6.12684 0.648923 6.22028 0.583177C6.31364 0.517341 6.43326 0.501112 6.54085 0.539508L10.7291 2.03567C10.869 2.08562 10.9623 2.21804 10.9623 2.36653V11.1298C10.9623 11.3239 10.805 11.4812 10.6109 11.4812L10.6109 11.4811ZM6.77385 10.7784H10.2595V2.61412L6.77385 1.3689V10.7784Z'
        fill='#6461F3'
      />
      <path
        d='M7.4082 3.00955C7.4082 2.81551 7.56551 2.6582 7.75955 2.6582C7.95359 2.6582 8.1109 2.81551 8.1109 3.00955V3.25183C8.1109 3.44587 7.95359 3.60317 7.75955 3.60317C7.56551 3.60317 7.4082 3.44587 7.4082 3.25183V3.00955Z'
        fill='#E052C2'
      />
      <path
        d='M7.4082 5.17019C7.4082 4.97615 7.56551 4.81885 7.75955 4.81885C7.95359 4.81885 8.1109 4.97615 8.1109 5.17019V5.41247C8.1109 5.60651 7.95359 5.76382 7.75955 5.76382C7.56551 5.76382 7.4082 5.60651 7.4082 5.41247V5.17019Z'
        fill='#E052C2'
      />
      <path
        d='M7.4082 7.3074C7.4082 7.11336 7.56551 6.95605 7.75955 6.95605C7.95359 6.95605 8.1109 7.11336 8.1109 7.3074V7.54968C8.1109 7.74372 7.95359 7.90102 7.75955 7.90102C7.56551 7.90102 7.4082 7.74372 7.4082 7.54968V7.3074Z'
        fill='#E052C2'
      />
      <path
        d='M8.91162 5.17019C8.91162 4.97615 9.06892 4.81885 9.26297 4.81885C9.45701 4.81885 9.61431 4.97615 9.61431 5.17019V5.41247C9.61431 5.60651 9.45701 5.76382 9.26297 5.76382C9.06892 5.76382 8.91162 5.60651 8.91162 5.41247V5.17019Z'
        fill='#E052C2'
      />
      <path
        d='M8.91162 7.3074C8.91162 7.11336 9.06892 6.95605 9.26297 6.95605C9.45701 6.95605 9.61431 7.11336 9.61431 7.3074V7.54968C9.61431 7.74372 9.45701 7.90102 9.26297 7.90102C9.06892 7.90102 8.91162 7.74372 8.91162 7.54968V7.3074Z'
        fill='#E052C2'
      />
      <path
        d='M7.4082 9.46805C7.4082 9.274 7.56551 9.1167 7.75955 9.1167C7.95359 9.1167 8.1109 9.274 8.1109 9.46805V9.71032C8.1109 9.90436 7.95359 10.0617 7.75955 10.0617C7.56551 10.0617 7.4082 9.90436 7.4082 9.71032V9.46805Z'
        fill='#E052C2'
      />
      <path
        d='M8.91162 9.46805C8.91162 9.274 9.06892 9.1167 9.26297 9.1167C9.45701 9.1167 9.61431 9.274 9.61431 9.46805V9.71032C9.61431 9.90437 9.45701 10.0617 9.26297 10.0617C9.06892 10.0617 8.91162 9.90437 8.91162 9.71032V9.46805Z'
        fill='#E052C2'
      />
      <path
        d='M4.53962 11.4812H0.351346C0.157266 11.4812 0 11.3239 0 11.1298V2.36653C0 2.21804 0.0933596 2.08562 0.233153 2.03568L4.42143 0.539509C4.52901 0.501111 4.64854 0.51734 4.742 0.583178C4.83544 0.649014 4.89099 0.756093 4.89099 0.870363V11.1298C4.89099 11.3239 4.73371 11.4811 4.53964 11.4811L4.53962 11.4812ZM0.702564 10.7785H4.18819V1.36885L0.702564 2.61407V10.7785Z'
        fill='#6461F3'
      />
      <path d='M4.54053 2.55713H6.42327V3.25982H4.54053V2.55713Z' fill='#6461F3' />
      <path d='M4.54053 9.40522H6.42327V10.1079H4.54053V9.40522Z' fill='#6461F3' />
      <path
        d='M2.85059 3.00955C2.85059 2.81551 3.00789 2.6582 3.20193 2.6582C3.39598 2.6582 3.55328 2.81551 3.55328 3.00955V3.25183C3.55328 3.44587 3.39598 3.60317 3.20193 3.60317C3.00789 3.60317 2.85059 3.44587 2.85059 3.25183V3.00955Z'
        fill='#E052C2'
      />
      <path
        d='M2.85059 5.17019C2.85059 4.97615 3.00789 4.81885 3.20193 4.81885C3.39598 4.81885 3.55328 4.97615 3.55328 5.17019V5.41247C3.55328 5.60651 3.39598 5.76382 3.20193 5.76382C3.00789 5.76382 2.85059 5.60651 2.85059 5.41247V5.17019Z'
        fill='#E052C2'
      />
      <path
        d='M2.85059 7.3074C2.85059 7.11336 3.00789 6.95605 3.20193 6.95605C3.39598 6.95605 3.55328 7.11336 3.55328 7.3074V7.54968C3.55328 7.74372 3.39598 7.90102 3.20193 7.90102C3.00789 7.90102 2.85059 7.74372 2.85059 7.54968V7.3074Z'
        fill='#E052C2'
      />
      <path
        d='M1.34912 5.17019C1.34912 4.97615 1.50642 4.81885 1.70047 4.81885C1.89451 4.81885 2.05181 4.97615 2.05181 5.17019V5.41247C2.05181 5.60651 1.89451 5.76382 1.70047 5.76382C1.50642 5.76382 1.34912 5.60651 1.34912 5.41247V5.17019Z'
        fill='#E052C2'
      />
      <path
        d='M1.34912 7.3074C1.34912 7.11336 1.50642 6.95605 1.70047 6.95605C1.89451 6.95605 2.05181 7.11336 2.05181 7.3074V7.54968C2.05181 7.74372 1.89451 7.90102 1.70047 7.90102C1.50642 7.90102 1.34912 7.74372 1.34912 7.54968V7.3074Z'
        fill='#E052C2'
      />
      <path
        d='M2.85059 9.46805C2.85059 9.274 3.00789 9.1167 3.20193 9.1167C3.39598 9.1167 3.55328 9.274 3.55328 9.46805V9.71032C3.55328 9.90437 3.39598 10.0617 3.20193 10.0617C3.00789 10.0617 2.85059 9.90436 2.85059 9.71032V9.46805Z'
        fill='#E052C2'
      />
      <path
        d='M1.34912 9.46805C1.34912 9.274 1.50642 9.1167 1.70047 9.1167C1.89451 9.1167 2.05181 9.274 2.05181 9.46805V9.71032C2.05181 9.90437 1.89451 10.0617 1.70047 10.0617C1.50642 10.0617 1.34912 9.90436 1.34912 9.71032V9.46805Z'
        fill='#E052C2'
      />
    </svg>
  );
};

export default FinancialInstitutions;
