const ReceiveableIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.57129 7.82052H9.99986L8.16322 3.96338L6.55608 5.46316L4.94893 3.96338L3.57129 6.32074V7.82052Z'
        stroke='#E052C2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10 10.3921H3.57143C2.15127 10.3921 1 9.24082 1 7.82066V1.39209'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ReceiveableIcon;
