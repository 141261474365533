const SMBIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='10' viewBox='0 0 11 10' fill='none'>
      <path
        d='M4.21436 9.01854V7.08972C4.21436 6.73463 4.50221 6.44678 4.8573 6.44678H6.14318C6.49826 6.44678 6.78612 6.73463 6.78612 7.08972V9.01854'
        stroke='#E052C2'
      />
      <path
        d='M4.01709 3.02246C4.01709 3.84164 4.68117 4.50572 5.50035 4.50572C6.31954 4.50572 6.98361 3.84164 6.98361 3.02246'
        stroke='#6461F3'
      />
      <path d='M4.01709 3.02214L4.02352 2.64795' stroke='#6461F3' />
      <path
        d='M4.01696 3.02246L3.91601 3.42494C3.79401 3.89222 3.45151 4.27048 2.9986 4.43815C2.54569 4.60581 2.03945 4.54175 1.64258 4.26655'
        stroke='#6461F3'
      />
      <path
        d='M1.64239 4.2666L1.60703 7.71726C1.6029 8.06096 1.73655 8.39201 1.97815 8.63651C2.21975 8.881 2.54918 9.0186 2.89291 9.01857H4.21415'
        stroke='#6461F3'
      />
      <path
        d='M1.6427 4.26641C1.12488 3.905 0.888273 3.25839 1.05056 2.64813L1.58741 1.2028C1.67689 0.882751 1.96811 0.661118 2.30043 0.660156H8.69961C9.03193 0.661118 9.32315 0.882751 9.41263 1.2028L9.94949 2.64877C10.1116 3.2587 9.87531 3.90493 9.35798 4.26641'
        stroke='#6461F3'
      />
      <path d='M4.21436 9.01825H6.78612' stroke='#6461F3' />
      <path d='M6.98388 3.02224L6.97938 2.64805' stroke='#6461F3' />
      <path
        d='M6.98535 3.02246L7.08629 3.42301C7.20805 3.89 7.55001 4.26819 8.00242 4.43619C8.45483 4.6042 8.96076 4.54087 9.3578 4.26655'
        stroke='#6461F3'
      />
      <path
        d='M9.35789 4.2666L9.39133 7.71726C9.39545 8.06096 9.2618 8.39201 9.0202 8.63651C8.7786 8.881 8.44917 9.0186 8.10545 9.01857H6.78613'
        stroke='#6461F3'
      />
    </svg>
  );
};

export default SMBIcon;
