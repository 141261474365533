const EngagementIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='13' height='13' viewBox='0 0 13 13' fill='none'>
      <path
        d='M4.14298 1.96345C3.86684 1.96345 3.64298 2.18731 3.64298 2.46345C3.64298 2.7396 3.86684 2.96345 4.14298 2.96345V1.96345ZM8.85727 2.96345C9.13341 2.96345 9.35727 2.7396 9.35727 2.46345C9.35727 2.18731 9.13341 1.96345 8.85727 1.96345V2.96345ZM3.07136 4.03507C3.07136 3.75893 2.84751 3.53507 2.57136 3.53507C2.29522 3.53507 2.07136 3.75893 2.07136 4.03507H3.07136ZM2.07136 8.74936C2.07136 9.0255 2.29522 9.24936 2.57136 9.24936C2.84751 9.24936 3.07136 9.0255 3.07136 8.74936H2.07136ZM10.9284 4.03507C10.9284 3.75893 10.7045 3.53507 10.4284 3.53507C10.1522 3.53507 9.92838 3.75893 9.92838 4.03507H10.9284ZM9.92838 8.74936C9.92838 9.0255 10.1522 9.24936 10.4284 9.24936C10.7045 9.24936 10.9284 9.0255 10.9284 8.74936H9.92838ZM4.14298 9.82047C3.86684 9.82047 3.64298 10.0443 3.64298 10.3205C3.64298 10.5966 3.86684 10.8205 4.14298 10.8205V9.82047ZM8.85727 10.8205C9.13341 10.8205 9.35727 10.5966 9.35727 10.3205C9.35727 10.0443 9.13341 9.82047 8.85727 9.82047V10.8205ZM4.14298 2.96345H8.85727V1.96345H4.14298V2.96345ZM2.07136 4.03507V8.74936H3.07136V4.03507H2.07136ZM9.92838 4.03507V8.74936H10.9284V4.03507H9.92838ZM4.14298 10.8205H8.85727V9.82047H4.14298V10.8205Z'
        fill='#6461F3'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.14286 2.46352C4.14286 3.33139 3.4393 4.03495 2.57143 4.03495C1.70355 4.03495 1 3.33139 1 2.46352C1 1.59564 1.70355 0.89209 2.57143 0.89209C2.9882 0.89209 3.3879 1.05765 3.6826 1.35235C3.9773 1.64705 4.14286 2.04675 4.14286 2.46352Z'
        stroke='#E052C2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9998 2.46352C11.9998 3.33139 11.2962 4.03495 10.4284 4.03495C9.56049 4.03495 8.85693 3.33139 8.85693 2.46352C8.85693 1.59564 9.56049 0.89209 10.4284 0.89209C11.2962 0.89209 11.9998 1.59564 11.9998 2.46352Z'
        stroke='#E052C2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.14286 10.3205C4.14286 11.1883 3.4393 11.8919 2.57143 11.8919C1.70355 11.8919 1 11.1883 1 10.3205C1 9.45258 1.70355 8.74902 2.57143 8.74902C2.9882 8.74902 3.3879 8.91458 3.6826 9.20928C3.9773 9.50398 4.14286 9.90368 4.14286 10.3205Z'
        stroke='#E052C2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.9998 10.3205C11.9998 11.1883 11.2962 11.8919 10.4284 11.8919C9.56049 11.8919 8.85693 11.1883 8.85693 10.3205C8.85693 9.45258 9.56049 8.74902 10.4284 8.74902C11.2962 8.74902 11.9998 9.45258 11.9998 10.3205Z'
        stroke='#E052C2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EngagementIcon;
