import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';
import Link from 'next/link';
import logoBlack from '../../../public/images/Logo-Black.svg';
import Image from 'next/image';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

interface Items {
  title: string;
  link: string;
  icon?: any;
  onClick?: (e: any) => void;
}
interface MenuDrawerProps {
  items: Items[] | Items[][];
  backAction?: () => void;
  closeDrawer: () => void;
  variant?: 'secondary' | 'primary';
}

const MenuDrawer = ({ items, backAction, closeDrawer }: MenuDrawerProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: '100%',
        '& .MuiListItemIcon-root ': {
          maxWidth: '20px !important',
        },
        '& a': {
          textDecoration: 'none',
          width: '100%',
        },
      }}
      role='presentation'
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '22px' }}>
        <Image src={logoBlack} alt='financeops logo' width={120} height={30} />
        <CloseIcon onClick={closeDrawer} sx={{ color: theme.palette.customColors.headingPrimary, fontSize: '16px' }} />
      </Box>
      <Divider sx={{ color: '#6f6f6f' }} />
      {backAction && (
        <Box
          onClick={backAction}
          sx={{
            display: 'flex',
            p: '22px',
            alignItems: 'center',
            gap: '11px',
            cursor: 'pointer',
          }}
        >
          <ArrowBackIosIcon
            sx={{
              color: theme.palette.primary.main,
            }}
          />
          <Typography
            sx={{
              color: theme.palette.primary.main,
              fontSize: '20px',
            }}
          >
            Back
          </Typography>
        </Box>
      )}
      <Divider sx={{ color: '#6f6f6f' }} />
      <List
        sx={{
          pl: '10px',
        }}
      >
        {!backAction &&
          (items as Items[]).map((item, index) => (
            <ListItem button key={index} onClick={item.onClick || closeDrawer}>
              {item.icon && (
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
              )}
              {item.link ? (
                <Link href={item.link} passHref>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '20px',
                        fontWeight: 600,
                        color: theme.palette.customColors.headingPrimary,
                      }}
                    >
                      {item.title}
                    </Typography>
                    {item.onClick && (
                      <KeyboardArrowRightIcon
                        sx={{
                          color: '#1e1e1e',
                          fontSize: '22px',
                        }}
                      />
                    )}
                  </Box>
                </Link>
              ) : (
                <ListItemText primary={item.title} />
              )}
            </ListItem>
          ))}
        {backAction &&
          (items as Items[][]).map((item, index) =>
            item.map((subItem, subIndex) => {
              return (
                <>
                  {index === 1 && subIndex === 0 && (
                    <>
                      <Divider
                        sx={{
                          py: '25px',
                        }}
                      />
                      <Typography sx={{ color: '#3E3E3E', fontSize: '12px', fontWeight: '700', p: '20px' }}>
                        BY INDUSTRY
                      </Typography>
                    </>
                  )}
                  <ListItem
                    button
                    key={subIndex}
                    onClick={subItem.onClick || closeDrawer}
                    sx={{
                      gap: '10px',
                    }}
                  >
                    {subItem.icon && (
                      <ListItemIcon
                        sx={{
                          maxWidth: '20px',
                        }}
                      >
                        <subItem.icon />
                      </ListItemIcon>
                    )}
                    {subItem.link ? (
                      <Link href={subItem.link} passHref>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            fontWeight: 500,
                            color: '#3E3E3E',
                            position: 'relative',
                            left: '-35px',
                          }}
                        >
                          {subItem.title}
                        </Typography>
                      </Link>
                    ) : (
                      <ListItemText primary={subItem.title} />
                    )}
                  </ListItem>
                </>
              );
            }),
          )}
      </List>
    </Box>
  );
};

export default MenuDrawer;
