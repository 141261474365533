export const RightAlignedBox = {
  '@media (min-width:768px)': {
    pl: '167px',
  },
};

export const CenterAlignBox = {
  '@media (min-width:768px)': {
    margin: '0 auto',
    maxWidth: '1000px',
  },
};
