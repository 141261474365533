const RiskManagementIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='13' height='11' viewBox='0 0 13 11' fill='none'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7537 7.79113L7.75952 1.30261C7.49603 0.857767 7.01737 0.584961 6.50034 0.584961C5.98332 0.584961 5.50466 0.857767 5.24117 1.30261L1.24628 7.79113C0.944206 8.26411 0.918235 8.86255 1.1782 9.35992C1.43816 9.8573 1.94433 10.1776 2.50511 10.1996H10.4949C11.0557 10.1776 11.5618 9.8573 11.8218 9.35992C12.0818 8.86255 12.0558 8.26411 11.7537 7.79113Z'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M6.50037 6.0791V2.6453' stroke='#E052C2' strokeLinecap='round' />
      <path d='M6.50037 8.13965V7.45289' stroke='#E052C2' strokeLinecap='round' />
    </svg>
  );
};

export default RiskManagementIcon;
