const AutomationIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
      <path
        d='M5.54594 2.25586H4.32166C3.23432 2.26215 2.35787 3.14852 2.3638 4.23586V7.71014'
        stroke='#6461F3'
        strokeLinecap='round'
      />
      <path d='M5.5457 2.25566L4.18213 0.89209' stroke='#6461F3' strokeLinecap='round' />
      <path d='M5.5457 2.25586L4.18213 3.61943' stroke='#6461F3' strokeLinecap='round' />
      <path
        d='M6.45508 9.5285H7.67865C8.76598 9.52221 9.64244 8.63584 9.63651 7.5485V4.07422'
        stroke='#E052C2'
        strokeLinecap='round'
      />
      <path d='M6.45459 9.52832L7.81816 10.8919' stroke='#E052C2' strokeLinecap='round' />
      <path d='M6.45459 9.52861L7.81816 8.16504' stroke='#E052C2' strokeLinecap='round' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.72714 9.07425C3.72675 9.82723 3.11608 10.4374 2.36309 10.4371C1.61011 10.4368 0.999869 9.82628 1 9.07329C1.00013 8.32031 1.61058 7.70996 2.36357 7.70996C2.72534 7.70996 3.07227 7.85372 3.32801 8.10959C3.58375 8.36547 3.72733 8.71248 3.72714 9.07425V9.07425Z'
        stroke='#6461F3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.27295 2.70976C8.27295 1.95668 8.88344 1.34619 9.63652 1.34619C10.3896 1.34619 11.0001 1.95668 11.0001 2.70976C11.0001 3.46284 10.3896 4.07333 9.63652 4.07333C8.88344 4.07333 8.27295 3.46284 8.27295 2.70976H8.27295Z'
        stroke='#E052C2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default AutomationIcon;
