import { Box, Button, SxProps, Theme } from '@mui/material';
import CommonButton from './Button';
import { PopupModal } from 'react-calendly';
import { useState } from 'react';
import { useRouter } from 'next/router';

const BookDemoButton = ({
  variant = 'primary',
  text = 'Book a demo',
  color = '',
  bgColor = '',
  buttonStyle = {},
  disabled = false,
  onClick,
  onClose,
  sx = {},
  redirectOnly = true,
}: {
  variant?: 'primary' | 'secondary' | 'tertiary' | 'new';
  text?: string;
  color?: string;
  bgColor?: string;
  disabled?: boolean;
  buttonStyle?: SxProps<Theme> | undefined;
  onClick?: () => void;
  onClose?: () => void;
  sx?: any;
  redirectOnly?: boolean;
}) => {
  const [showCalendly, setShowCalendly] = useState(false);
  const calendlyProfile = process.env.NEXT_PUBLIC_CALENDLY_PROFILE || 'yogesh-financeops/30min';
  const router = useRouter();

  return (
    <Box>
      {showCalendly && (
        <PopupModal
          url={`https://calendly.com/${calendlyProfile}`}
          rootElement={document.getElementById('calendly') as HTMLElement}
          open={showCalendly}
          onModalClose={() => {
            setShowCalendly(false);
            onClose?.();
          }}
        />
      )}
      {variant === 'tertiary' ? (
        <Button
          onClick={() => {
            onClick?.();
            if (redirectOnly) {
              router.push('/contact-us');
            } else {
              setShowCalendly(true);
            }
          }}
          disabled={disabled}
          sx={{
            border: '1px solid #D9D9D9',
            borderRadius: '23px',
            color: color ? color : '#3E3E3E',
            fontSize: '14px',
            textTransform: 'none',
            background: bgColor ? bgColor : '#6461F3',
            ...buttonStyle,
          }}
        >
          {text}
        </Button>
      ) : variant === 'new' ? (
        <Button
          onClick={() => {
            onClick?.();
            if (redirectOnly) {
              router.push('/contact-us');
            } else {
              setShowCalendly(true);
            }
          }}
          disabled={disabled}
          sx={{
            borderRadius: '12px',
            color: '#fff',
            fontSize: '12px',
            textTransform: 'none',
            background: ' linear-gradient(92.687deg, rgba(255, 255, 255, 13%) 0%, rgba(255, 255, 255, 25%) 100%)',
            fontWeight: '700',
            transition: '0.5s all',
            '&:hover': {
              background: 'linear-gradient(92.687deg, rgba(31, 25, 51, 50%) 0%, rgba(100, 97, 243, 50%) 100%)',
            },
          }}
        >
          {' '}
          {text}
        </Button>
      ) : (
        <CommonButton
          text={text}
          type={variant}
          onClick={() => {
            onClick?.();
            if (redirectOnly) {
              router.push('/contact-us');
            } else {
              setShowCalendly(true);
            }
          }}
        />
      )}
    </Box>
  );
};

export default BookDemoButton;
